

.country-card-wrapper {
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
    margin:15px;
}

.filters-wrapper{
    display: flex;
    justify-content: space-around;
    padding-top: 20px;
    padding-bottom: 20px;
    flex-wrap: wrap;
}